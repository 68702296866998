// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kapcsolat-js": () => import("./../../../src/pages/kapcsolat.js" /* webpackChunkName: "component---src-pages-kapcsolat-js" */),
  "component---src-pages-orarend-js": () => import("./../../../src/pages/orarend.js" /* webpackChunkName: "component---src-pages-orarend-js" */),
  "component---src-templates-event-list-template-js": () => import("./../../../src/templates/event-list.template.js" /* webpackChunkName: "component---src-templates-event-list-template-js" */),
  "component---src-templates-event-post-template-js": () => import("./../../../src/templates/event-post.template.js" /* webpackChunkName: "component---src-templates-event-post-template-js" */)
}

